import { Typography, Grid } from "@mui/material"; // Cambia de @material-ui/core a @mui/material
import BusinessTwoToneIcon from "@mui/icons-material/BusinessTwoTone"; // Cambia la ruta de importación
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk"; // Cambia la ruta de importación
import EmailIcon from "@mui/icons-material/Email"; // Cambia la ruta de importación
import DataUsageTwoToneIcon from "@mui/icons-material/DataUsageTwoTone"; // Cambia la ruta de importación
import Link from "@mui/material/Link"; // Esta línea no necesita cambio
import './footer.css';

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      align="center"
      {...props}
    >
      Derechos Reservados © 
      <Link color="inherit" href="https://rolandimpresores.co/" target="_blank">
      Roland Impresores
      </Link>{" "}
      {new Date().getFullYear()}
    </Typography>
  );
}

export default function Footer() {
  return (
    <div id="container-footer">
      <Grid style={{ paddingLeft:'10%',paddingRight:'10%'}}>
        <Grid
          container
          style={{
            justifyContent: "space-around",
            color: "#757170",
            padding: "1%",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <Grid>
            <PhoneInTalkIcon />
            <Typography> PBX: (57 -1 )383 84 62</Typography>
          </Grid>
          <Grid>
            <BusinessTwoToneIcon />
            <Typography>Cra. 28A No. 74 - 59 </Typography>
            <Typography>Bogotá, Colombia.</Typography>
          </Grid>
          <Grid style={{ justifyContent: "space-between" }}>
      
          
            <EmailIcon />
            <Typography> roland@rolandimpresores.co</Typography>
          
        </Grid>
          <Grid>
            <DataUsageTwoToneIcon />
            <Link href="https://drive.google.com/file/d/133EbR-lq2kx9asjLoVET-egZdpPoecro/view?usp=sharing" target="_blank" style={{color: "#757170"}}>
              <Typography>Politica de tratamiento de datos</Typography>
            </Link>
          </Grid>
        </Grid>
        <Copyright sx={{ mt: 8, mb: 4 }} style={{color: "#757170"}}/>
      </Grid>
      
    </div>
  );
}
