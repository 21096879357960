import React, { useState } from 'react';
import './WhatsAppIcon.css';
import { WhatsApp } from '@mui/icons-material';

const WhatsAppIcon = () => {
  const [isBubbleVisible, setIsBubbleVisible] = useState(true);

  const handleCloseBubble = () => {
    setIsBubbleVisible(false);
  };

  return (
    <>
      {isBubbleVisible && (
      <div className="contact-bubble">
      
      <span className="star">★</span>
        
        ¡Hola! ¿En qué podemos ayudarte?
      
      <button className="close-btn" onClick={handleCloseBubble}>X</button>
    </div>
    
      )}
      <div className="whatsapp-icon">
        <a href="https://wa.me/3006134708" target="_blank" rel="noopener noreferrer">
          <WhatsApp style={{ fontSize: 29 }} />
        </a>
      </div>
    </>
  );
};

export default WhatsAppIcon;
