
import './Tecnologia.css'
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';

export default function Card(props) {
  //buttonText={item.buttonText} buttonLink={item.buttonLink} />
  return (
      
  <div className="align">    
    <div className="rowp" style={{marginBottom: '3%'}}>
    <div className=" text-card-tech column-2" style={{marginBottom: '2%', marginTop: '1.7%'}}>
        <div style={{padding: '1%',textAlign: 'center', marginRight: '5%'}}>
          <h1 >{props.title}</h1>
          <ul style={{textAlign: 'left',userSelect: 'none'}}>
            {props.descripcion.map((item, index) => (
              <div>
              <li key={index} style={{listStyleType: 'none'}}>
              <br/> 
                <strong className="text-align">
                  {item.substring(0, item.indexOf(':')+1)}</strong>{item.substring(item.indexOf(':')+1)}<br/><br/>
              </li>
              </div>
            ))}
            <Link to={props.buttonLink} style={{textDecoration: 'none', color: 'black'}}>
            <Button id="contactanos" variant="outlined"  href="https://wa.me/3006134708"style={{color: '#110023', borderColor: '#110023', marginTop: '40px', borderRadius: '20px'}} onMouseOver={(e) => {e.target.style.backgroundColor = '#110023'; e.target.style.color = 'white'}} onMouseOut={(e) => {e.target.style.backgroundColor = 'white'; e.target.style.color = '#110023'}}>{props.buttonText}</Button>

              </Link>
            
          </ul>
          </div>
        
      </div>
      <div className="column-2">
      
            <img className="img" src={props.img} width={'100%'} alt="" style={{borderRadius: '5%'}}/>   
      
      </div>
      
    </div>
    </div>
    
  );
}
