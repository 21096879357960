import Dimar from '../../images/historias/1dimar.png';
import BRepublica from '../../images/historias/2banrepublica.png';
import Sancho from '../../images/historias/3sancho.png';
import Fncc from '../../images/historias/4fncc.png';
import Colcundinamarca from '../../images/historias/5colcundinamarca.png';
import Minambiente from '../../images/historias/6minambiente.png';
import Presidencia from '../../images/historias/7presidencia.png';
import './Cliente.css'
import { Button } from '@mui/material';

const images = [Dimar, BRepublica, Sancho, Fncc, Colcundinamarca, ];
const images2 = [Minambiente, Presidencia];

export default function Cliente() {
    return(
        <div id='contenedor-tech' style={{backgroundColor: '#f2f2f2'}}>
            <div className="client-text">
                <h1 className="client-text" id="titulo">Clientes</h1>
            </div>
            <div>
            <div id="row1">
            {
                images.map((item,key) => (
                    <div key={key} id="column1">
                        <img src={item} width={100} alt=""/>
                    </div>
                ))
            }
            </div>
                <div id="row2">
                {images2.map((item,key) => (
                        <div key={key} id="column2">
                            <img src={item}width={400} alt=""/>
                        </div>
                    ))}
                </div>
            </div>
            <div style={{display: 'flex', justifyContent: 'center', marginBottom: '50px', paddingBottom: '50px'}}>
            <Button id="contactanos" variant="outlined"  href="https://wa.me/3006134708"style={{color: '#110023', borderColor: '#110023', marginTop: '40px', borderRadius: '20px'}} onMouseOver={(e) => {e.target.style.backgroundColor = '#110023'; e.target.style.color = 'white'}} onMouseOut={(e) => {e.target.style.backgroundColor = 'white'; e.target.style.color = '#110023'}}>Contáctanos</Button>
            </div>

        </div>
        

    );    
}