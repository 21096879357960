const info = {
  info: [
    {
      title: "Equipamiento de Precisión",
      descripcion: [
        "Maquinaria Avanzada: Utilizamos maquinaria avanzada para garantizar la precisión en cada paso del proceso de impresión.",
        //"Materiales de Calidad: Seleccionamos cuidadosamente los mejores materiales disponibles en el mercado para asegurar resultados de alta calidad en tus proyectos.",
        "Excelencia Garantizada: Nuestro compromiso con la excelencia nos impulsa a ofrecer resultados superiores en todos tus proyectos de impresión."
      ],
      img: "../assets/tecnologias/3_tech.png",
      buttonText: "Conocenos un poco más",
      buttonLink: "/papeleria" // Enlace a más información o detalles específicos
    },
    {
      title: "Intaglio",
      descripcion: [
        "Impresos Seguros y Tecnológicos: Ofrecemos impresos innovadores que cumplen con tus especificaciones, asegurando calidad y seguridad en cada trabajo.",
        //"Tintas Intaglio de Primera Calidad: Nuestro proceso de impresión incluye el uso de tintas Intaglio de primera calidad, garantizando resultados de alta definición y durabilidad.",
        "Equipos: Contamos con máquinas y materiales que aseguran una producción eficiente y de alta calidad en todo momento."
      ],
      img: "../assets/tecnologias/1_tech.png",
      buttonText: "Descubre más",
      buttonLink: "/papeleria"
    },
    {
      title: "Impresión",
      descripcion: [
        "Mejor Impresión para tus Diseños: Nos enfocamos en brindar la mejor impresión para tus diseños, empleando tecnología digital avanzada y papeles de seguridad para resultados excepcionales.",
        //"Tecnología Digital Avanzada: Utilizamos tecnología digital de última generación que garantiza una reproducción precisa de tus diseños, incluso en los detalles más finos.",
        "Papeles de Seguridad: Empleamos papeles de seguridad de alta calidad que protegen tus documentos y aseguran la confidencialidad de la información impresa."
      ],
      img: "../assets/tecnologias/2_tech.png",
      buttonText: "Ver más",
      buttonLink: "/papeleria"
    }
  ]
};

export default info;
