import { Container, Typography, Grid, Card, CardContent, Button, Box, Tabs, Tab, TextField ,Divider} from '@mui/material';
import { styled } from '@mui/system';
import { useState } from 'react';
import MainBar from '../MainBar';
import WhatsAppIcon from '../Whatsapp/WhatsappIcon';
import Footer from '../Footer/Footer';

// Estilos personalizados usando styled
const StyledCard = styled(Card)(({ theme }) => ({
    marginTop: '1.3rem',
    transition: '0.3s',
    borderRadius: '12px', // Aumentar el radio de borde para más redondez
    boxShadow:  '0 0 10px rgba(33,33,33,.2)', // Sutil sombra para resaltar el card
    maxWidth: '97%',
    '&:hover': {
      transform: 'scale(1.05)',
      boxShadow: '0 0 20px rgba(33,33,33,.2)', 
    },
  }));


const StyledTab = styled(Tab)(({ theme }) => ({
  '&:hover': {
    backgroundColor: '#e0e0e0',
  },
}));  

const pdfCategories = {
    'Ministerios': [
        { title: 'Hojas Dimar', url: 'https://drive.google.com/file/d/1FveNp2d0G6W1g75hUeJcfWn0FRNxgo19/view?usp=drive_link', imageUrl:require('../../images/portafolio/3.png'), description: 'Hojas utilizadas para realizar exportaciones (Portal Marítimo de Defensa Nacional).' },
    //    { title: 'Bolsas Ministerio Defensa', url: '/pdfs/na.pdf', imageUrl: require('../../images/portafolio/3.png'), description: 'Bolsas utilizadas en colaboración con el Ministerio de Defensa.' },
      ],
  'Diplomas y Certificados': [
    { title: 'Diplomas Universitarios', url: 'https://drive.google.com/file/d/1jTr9ZWrwuC4_q9rz6CbWdmxLCGd_Vocl/view?usp=sharing', imageUrl: require("../../images/portafolio/Diplomas.png"), description: 'Ejemplos de diplomas universitarios que ofrecemos.' },
    { title: 'Actas de Grado', url: 'https://drive.google.com/file/d/14OL_gD1TXthu-oL3DaRD82mXDZi8ZBLK/view?usp=sharing', imageUrl:require('../../images/portafolio/Diplomas.png'), description: 'Actas de grado personalizadas para instituciones educativas.' },
  ],
  'Papelería': [
    { title: 'Papelería Presidencia', url: 'https://drive.google.com/file/d/1BXFXgOIzapWJqA4BUFmfyflQWVHCVOkj/view?usp=drive_link', imageUrl:require('../../images/portafolio/2.png'), description: 'Muestras de nuestra papelería armada.' },
    { title: 'Papelería Banco de la República', url: 'https://drive.google.com/file/d/10VIgeYAA3q9ORxnH3k2_2PPls2Is8bvv/view?usp=drive_link', imageUrl:require('../../images/portafolio/2.png'), description: 'Documentos oficiales del Banco de la República.' },
  ],
};

// Crear la categoría "Todos"
const allFiles = Object.values(pdfCategories).flat();

function Papeleria() {
  const [value, setValue] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const categories = ['Todos', ...Object.keys(pdfCategories)];

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setSearchQuery(''); // Resetea el campo de búsqueda al cambiar de categoría
  };

  // Filtra los archivos según la consulta de búsqueda
  const filteredFiles = (value === 0 ? allFiles : pdfCategories[categories[value]]).filter(file =>
    file.title.toLowerCase().includes(searchQuery.toLowerCase())
  );
  return (
    <div>
        <MainBar color="#110023"/>
        <div style={{ backgroundColor: '#f5f5f5', padding: '1rem 0', marginTop: '5%' }}>
            <Container>
                <Typography variant="h4" align="center" gutterBottom style={{ marginBottom: '1rem', marginTop: '2rem' }}>
                    Nuestra Papelería
                </Typography>
                <TextField
                    placeholder="Buscar archivos..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    style={{
                        marginBottom: '1.3rem',
                        width: '100%',
                        borderRadius: '8px',
                        backgroundColor: 'white',
                        boxShadow: '0 0 10px rgba(33,33,33,.2)',
                    }}
                />
                <Tabs value={value} onChange={handleChange} centered>
                    {categories.map((category, index) => (
                        <StyledTab key={index} label={category} style={{ minWidth: 'auto' }} />
                    ))}
                </Tabs>

                <Box sx={{ overflowY: 'auto', maxHeight: '70vh', mt: 3,mb:3 }}>
                    {value === 0 ? (
                        // Secciones para "Todos"
                        Object.keys(pdfCategories).map((category, index) => (
                            <div key={index}>
                                <Typography variant="h5" gutterBottom style={{ marginTop: '2rem' }}>{category}</Typography>
                                <Divider sx={{ marginBottom: '1rem' }} /> {/* Línea divisoria */}
                                <Grid container spacing={4}>
                                    {pdfCategories[category].filter(file =>
                                        file.title.toLowerCase().includes(searchQuery.toLowerCase())
                                    ).map((file, fileIndex) => (
                                        <Grid item xs={12} sm={6} md={4} key={fileIndex}>
                                            <StyledCard>
                                                <CardContent>
                                                <img src={
                                                file.imageUrl
                                                } alt="imageheader" style={{ 
                          width: '100%', 
                          height: '150px', // Altura fija para recortar la imagen
                          objectFit: 'cover', // Recorta la imagen para que no se distorsione
                          borderRadius: '8px' 
                        }}  />
                                                    <Typography variant="h6" align="center" gutterBottom>
                                                        {file.title}
                                                    </Typography>
                                                    <Typography variant="body2" align="center" gutterBottom>
                                                        {file.description}
                                                    </Typography>
                                                    <div style={{ display: 'flex', justifyContent: 'center'}}>
                                                        <Button 
                                                            id="contactanos" 
                                                            variant="outlined" 
                                                            
                                                            style={{
                                                                color: '#110023',
                                                                borderColor: '#110023',
                                                                marginTop: '40px',
                                                                borderRadius: '20px',
                                                            }} 
                                                            onMouseOver={(e) => {
                                                                e.target.style.backgroundColor = '#110023'; 
                                                                e.target.style.color = 'white';
                                                            }} 
                                                            onMouseOut={(e) => {
                                                                e.target.style.backgroundColor = 'white'; 
                                                                e.target.style.color = '#110023';
                                                            }}
                                                            onClick={() => window.open(file.url, '_blank')}
                                                        >
                                                            Ver PDF
                                                        </Button>
                                                    </div>
                                                </CardContent>
                                            </StyledCard>
                                        </Grid>
                                    ))}
                                </Grid>
                            </div>
                        ))
                    ) : (  // Mostrar archivos de la categoría seleccionada
                        <div>
                            <Typography variant="h5" gutterBottom style={{ marginTop: '2rem' }}>{categories[value]}</Typography>
                            <Divider sx={{ marginBottom: '1rem' }} /> {/* Línea divisoria */}
                            <Grid container spacing={4}>
                                {filteredFiles.map((file, index) => (
                                    <Grid item xs={12} sm={6} md={4} key={index}>
                                        <StyledCard>
                                            <CardContent>
                                            <img src={require("../../images/portafolio/Diplomas.png")} alt="imageheader" style={{ 
                          width: '100%', 
                          height: '150px', // Altura fija para recortar la imagen
                          objectFit: 'cover', // Recorta la imagen para que no se distorsione
                          borderRadius: '8px' 
                        }}  />
                                                <Typography variant="h6" align="center" gutterBottom>
                                                    {file.title}
                                                </Typography>
                                                <Typography variant="body2" align="center" gutterBottom>
                                                    {file.description}
                                                </Typography>
                                                <div style={{ display: 'flex', justifyContent: 'center'}}>
                                                    <Button 
                                                        id="contactanos" 
                                                        variant="outlined" 
                                                        style={{
                                                            color: '#110023',
                                                            borderColor: '#110023',
                                                            marginTop: '40px',
                                                            borderRadius: '20px',
                                                        }} 
                                                        onMouseOver={(e) => {
                                                            e.target.style.backgroundColor = '#110023'; 
                                                            e.target.style.color = 'white';
                                                        }} 
                                                        onMouseOut={(e) => {
                                                            e.target.style.backgroundColor = 'white'; 
                                                            e.target.style.color = '#110023';
                                                        }}
                                                        onClick={() => window.open(file.url, '_blank')}
                                                    >
                                                        Ver PDF
                                                    </Button>
                                                </div>
                                            </CardContent>
                                        </StyledCard>
                                    </Grid>
                                ))}
                            </Grid>
                        </div>
                    )}
                </Box>
            </Container>
        </div>
        <WhatsAppIcon />
        <br />
        < br />
        <Footer />
    </div>
);
}

export default Papeleria;