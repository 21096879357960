import React, { useState } from 'react';
import {
  Container, TextField, Button, Typography, Grid, Paper, Box, Alert, Snackbar,
  FormControl, InputLabel, Select, MenuItem, CircularProgress
} from '@mui/material';
import emailjs from '@emailjs/browser'; // Importar EmailJS
import MainBar from '../MainBar';
import WhatsAppIcon from '../Whatsapp/WhatsappIcon';
import Footer from '../Footer/Footer';



function CotizacionForm() {
  const [formData, setFormData] = useState({
    nombre: '',
    email: '',
    telefono: '',
    descripcion: '',
    categoria: '',
    archivo: null,
  });

  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    const formDataToSend = new FormData();
    formDataToSend.append('nombre', formData.nombre);
    formDataToSend.append('email', formData.email);
    formDataToSend.append('telefono', formData.telefono || ''); // No obligatorio
    formDataToSend.append('descripcion', formData.descripcion);
    formDataToSend.append('categoria', formData.categoria);
    if (formData.archivo) {
      formDataToSend.append('archivo', formData.archivo); // Agregar archivo
    }
    const templateParams = {
        nombre: formData.nombre,
        email: formData.email,
        telefono: formData.telefono || '', // No obligatorio
        descripcion: formData.descripcion,
        categoria: formData.categoria,
    };

    emailjs.send(
      'service_fse6lkm',   // Reemplaza con tu Service ID
      'template_22q0zxe',  // Reemplaza con tu Template ID
      templateParams,
        {
            'publicKey':'Ln2MxOxH-M_pNI183'      // Reemplaza con tu Public Key
        }        
    )
    .then(() => {
      setLoading(false);
      setOpenSnackbar(true);
    })
    .catch((error) => {
      console.error('Error al enviar el correo:', error);
      setLoading(false);
    });
  };

  return (
    <div style={{backgroundColor: '#f5f5f5'}}>
        <div>
        <MainBar color="#110023" />
        </div>
    <Container maxWidth="sm" sx={{ marginBottom: '3%',paddingTop: '7%'}}>
      <Paper elevation={3} sx={{ padding: '2rem', borderRadius: '12px'  }}>
        <Typography variant="h5" align="center" gutterBottom>
          Solicitar Cotización
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Nombre"
                name="nombre"
                value={formData.nombre}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Correo Electrónico"
                name="email"
                type="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Celular"
                name="telefono"
                type="number"
                value={formData.telefono}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth required>
                <InputLabel>Categoría</InputLabel>
                <Select
                  name="categoria"
                  value={formData.categoria}
                  onChange={handleChange}
                >
                  <MenuItem value="Servicios">Servicios</MenuItem>
                  <MenuItem value="Productos">Productos</MenuItem>
                  <MenuItem value="Otros">Otros</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Descripción"
                name="descripcion"
                value={formData.descripcion}
                onChange={handleChange}
                multiline
                rows={4}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                fullWidth
                sx={{ marginTop: '1rem' }}
                disabled={loading}
              >
                {loading ? <CircularProgress size={24} /> : 'Enviar Solicitud'}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Paper>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={() => setOpenSnackbar(false)}
      >
        <Alert onClose={() => setOpenSnackbar(false)} severity="success">
          ¡Solicitud enviada exitosamente!
        </Alert>
      </Snackbar>
    </Container>
    <WhatsAppIcon />
    <br />
    <br /><br />
    <Footer />
    </div>
  );
}

export default CotizacionForm;
