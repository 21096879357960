import './header.css';
import Slide from "@mui/material/Slide";
import rolandImage from '../../images/roland.png'; // Importa la imagen

export default function Header() {
    return (
        <header className="header noselect">
            <div width="100%" id="container">
                <img src={require("../../images/2_header.png")} alt="imageheader" className="image" />
                
                <div id="header-text">
                    <Slide direction="down" in={true} timeout={1200}>
                    <div className='header-text' style={{ display: "flex", alignItems: "center" , justifyContent: "center"}}>
                    <img
                        src={rolandImage}
                        alt="imageheader"
                        style={{ width: "14%", height: "auto", objectFit: "cover" , marginRight: "10px"}}
                    />
                    <p id="white-text"> ROLAND IMPRESORES</p>
                </div>
                    </Slide>   
                    <Slide direction="left" in={true} timeout={1200}>
                        <hr id="hr" />
                    </Slide>
                    <Slide direction="up" in={true} timeout={1200}>
                        <p id="white-text">Seguridad y Tecnología en impresión</p>
                    </Slide>
                </div>
            </div>
        </header>
    );
}
