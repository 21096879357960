import React from "react";
import Header from "./Header/Header";
import Cliente from "./Historia/Cliente";
import MainBar from "./MainBar";
import Nosotros from "./Nosotros/Nosotros";
import Tecnologia from "./Tecnologia/Tecnologia.jsx";
import Footer from "./Footer/Footer";
import WhatsAppIcon from "./Whatsapp/WhatsappIcon.jsx";

export default function MainPage() {
  return (
    <div >
      <MainBar />
      <section id="header">
        <Header />
      </section>
      <section id="nosotros">
        <Nosotros />
      </section>
      <section id="tecnología">
        <Tecnologia />
      </section>
      <section id="clientes">
        <Cliente />
      </section>
      <WhatsAppIcon />
      <Footer />
    </div>
  );
}
