import './nosotros.css'
import us1 from '../../images/1us.png';
import us2 from '../../images/2us.png';
import us3 from '../../images/3us.png';
import Card from './CardNosotros'
import { Button } from '@mui/material';
export default function Nosotros() {
    const texto = ['Somos una empresa especializada en impresión Intaglio y otras técnicas de seguridad.', 'Nuestros productos y servicios se distinguen por su calidad y seguridad.', 'Somos una organización responsable y comprometida con la satisfacción de nuestros clientes.']
    const arr = [us3, us2, us1]
    return (
        <div id='contenedor-nosotros'> 
        <div id="container-text">
            <h1 id="titulo">Nosotros</h1>
            <p id="descripcion">
            Llevamos más de <strong>30 años</strong> en el sector <strong>gráfico</strong>, contando con profesionales y expertos dedicados, quienes poseen una basta <strong>experiencia</strong>. Ofrecemos servicios de alta <strong>calidad</strong> y una amplia gama de <strong>productos</strong> para satisfacer todas tus necesidades gráficas. 
            </p>
            
            <Button id="contactanos" variant="outlined"  href="https://wa.me/3006134708"style={{color: '#110023', borderColor: '#110023', marginTop: '9%', borderRadius: '20px'}} onMouseOver={(e) => {e.target.style.backgroundColor = '#110023'; e.target.style.color = 'white'}} onMouseOut={(e) => {e.target.style.backgroundColor = 'white'; e.target.style.color = '#110023'}}>Contáctanos</Button>
            </div>
            <div id="row" style={{marginBottom: '5%'}}>
          
            {arr.map((item,key) => (
                    <div key={key} id="column">
                        <Card image= {item} texto ={texto[key]} />
                    </div>
                ))
            }
            </div>
                    
        </div>

    );
}