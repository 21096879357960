import "./App.css";
import Contactanos from "./MainComponents/Contactanos/Contactanos";
import MainPage from "./MainComponents/MainPage";
import Sesion from "./MainComponents/Sesion/Sesion";
import Papeleria from "./MainComponents/Papeleria/Papeleria";



import { BrowserRouter as Router, Route, Routes } from "react-router-dom";




function App() {
  return (
    <div>

      <Router>
     
        <Routes>
          <Route exact path="/" element={<MainPage/>} />
          <Route exact path="/bienvenida" element={<MainPage/>} />
          <Route exact path="/sesion" element={<Sesion/>} />
          <Route path = "*" element={<MainPage/>} />
          <Route path = "/contactanos" element={<Contactanos/>} />
          <Route path = "/papeleria" element={<Papeleria/>} />
        </Routes>
      </Router>
      
    </div>
  );
}

export default App;
