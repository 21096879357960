import Info from './Info';
import Card from './Card';
import './Tecnologia.css';
import { useState, useRef } from 'react';
//links
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

export default function Tecnologia() {
  const [activeSlide, setActiveSlide] = useState(0);
  const carouselRef = useRef(null); // Usamos una referencia para el carrusel

  const handleSlideChange = (index) => {
    const totalSlides = Info.info.length;
    const forwardDistance = (index - activeSlide + totalSlides) % totalSlides;
    const backwardDistance = (activeSlide - index + totalSlides) % totalSlides;

    // Verificamos cuál es la distancia más corta
    if (forwardDistance < backwardDistance) {
      carouselRef.current.goToSlide(index); // Vamos hacia adelante
    } else {
      carouselRef.current.goToSlide(index); // Vamos hacia atrás
    }

    setActiveSlide(index); // Actualizamos el estado del slide activo
  };

  const renderDots = () => {
    return Info.info.map((item, index) => (
      <div
        key={index}
        className={`dot ${index === activeSlide ? 'active' : ''}`}
        onClick={() => handleSlideChange(index)} // Cambiar de slide al hacer clic
      />
    ));
  };

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  return (
    <div id="contenedor-tech">
      <div className="title-tech" style={{ marginBottom: '5%' }}>
        <h1 id="titulo">Nuestro Liderazgo en Tecnología e Impresión</h1>
      </div>
      <div className="carousel">
        <Carousel
          ref={carouselRef} // Asignamos la referencia al carrusel
          responsive={responsive}
          infinite={true}
          autoPlay={false} // Para evitar conflicto con el autoPlay
          keyBoardControl={true}
          transitionDuration={500}
          arrows={false} // Sin flechas
          beforeChange={(nextSlide) => setActiveSlide(nextSlide)} // Sincronizar el slide activo
        >
          {Info.info.map((item, index) => (
            <Card key={index} img={item.img} title={item.title} descripcion={item.descripcion} buttonText={item.buttonText} buttonLink={item.buttonLink} />
          ))}
        </Carousel>
        <div className="dot-container">{renderDots()}</div>
      </div>
    </div>
  );
}
