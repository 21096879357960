/* Creating the appbar */

import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import useScrollTrigger from '@mui/material/useScrollTrigger';
import {
  Link
} from "react-router-dom";





const pages = ["BIENVENIDA", "PAPELERIA", "CONTACTANOS"];

const ResponsiveAppBar = (props) => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  //Transparent but with a little bit of color
  
  const [color] = React.useState(props.color ? props.color : 'rgba(0, 0, 0, 0.1)');
 

  function ElevationScroll(props) {
    const { children, window } = props;
    
    const trigger = useScrollTrigger({
      disableHysteresis: true,
      threshold: 0,
      target: window ? window() : undefined,
    });
  
    return( 
      
      React.cloneElement(children, {
      elevation: trigger ? 4 : 0,
      style: {
        backgroundColor: trigger ? '#110023' : color,
        transition: 'background-color 0.5s'
      },
      })
      
    );
    
  }
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  
  return (
    <div>
    
    <ElevationScroll {...props}>
      <AppBar>
        <Container maxWidth="xl">
          <Toolbar disableGutters sx={{paddingLeft:'7%', paddingRight:'3%'}}>
            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                {pages.map((page) => (
                  <MenuItem key={page}
                  onClick={handleCloseNavMenu}>
                    <Link to={page.toLowerCase() !== 'sesión' ? "#" + page.toLowerCase() :  "/users"}>
                      <Typography textAlign="center">{page}</Typography>
                    </Link>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
           <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
           <Button
                  onClick={handleCloseNavMenu}
                  sx={{ my: 2, color: "white", display: "block" }}
                  component={Link}
                  to="/"
                >
                  ROLAND IMPRESORES
                </Button>
           </Box>
            <Box sx={{ flexGrow: 1,justifyContent:'space-around', display: { xs: "none", md: "flex" } }}>
              {pages.map((page,key) => (
                <Button
                  key={key}
                  onClick={handleCloseNavMenu}
                  component={Link}
                  //delete the # from the next line
                  to={"/" + page.toLowerCase()}
                  sx={{ my: 2, color: "white", display: "block" }}
                >  
                  {page}   
                </Button>
              ))}
              <Button
              component={Link}
              to="/sesion"
              sx={{ my: 2, color: "white", display: "block" }}
              >
                  SESIÓN
                </Button>
            </Box>            
          </Toolbar>      
        </Container>
      </AppBar>
    </ElevationScroll>
     
    </div>
  );
};
export default ResponsiveAppBar;
